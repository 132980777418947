import { useMemo, useContext } from 'react';
import propTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { queries } from '@kiper/monitoring-graphql/condominium/detail';
import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import Context from './CondominiumDetailContext';
import { profileTypes } from '../../../constants';

const INITIAL_STATE_CONDOMINIUM_WORKERS = {
  condominiumManagers: [],
  caretakers: [],
};

const Provider = ({ children, match, route, history }) => {
  const { toast } = useSwal();

  const { data, loading, refetch } = useQuery(queries.condominium, {
    fetchPolicy: 'no-cache',
    variables: {
      personContextId: Number(match.params.id),
    },
    onError: err => {
      const formattedErrors = apolloErrorHandler(err);
      if (formattedErrors && formattedErrors.length) {
        toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
        history.push('/condominiums');
      }
    },
  });

  const condominium = useMemo(() => {
    if (!data) return {};
    const { addresses = [] } = data.condominium;
    return {
      ...data.condominium,
      mainAddress: addresses ? addresses.find(x => x.isPrincipal) : null,
    };
  }, [data]);

  const { data: condoWorkersData, loading: condoWorkersLoading } = useQuery(
    queries.getCondominiumWorkers,
    {
      skip: !condominium.personContextId,
      variables: {
        condominiumPersonContextId: condominium.personContextId,
      },
    },
  );

  const condoWorkers = useMemo(() => {
    if (!condoWorkersData) return INITIAL_STATE_CONDOMINIUM_WORKERS;
    const { condominiumWorkers } = condoWorkersData;
    if (condominiumWorkers.length === 0) {
      return INITIAL_STATE_CONDOMINIUM_WORKERS;
    }
    const caretakersFilter = condominiumWorkers.filter(
      worker => worker?.profile?.fieldName === profileTypes.caretaker,
    );
    const condominiumManagersFilter = condominiumWorkers.filter(
      worker => worker?.profile?.fieldName !== profileTypes.caretaker,
    );
    return {
      condominiumManagers: condominiumManagersFilter,
      caretakers: caretakersFilter,
    };
  }, [condoWorkersData, data]);

  const breadcrumb = useMemo(() => {
    if (!condominium) return route.breadcrumb;

    const modifiedBreadcrumb = [...route.breadcrumb];
    modifiedBreadcrumb.splice(modifiedBreadcrumb.length - 1, 1);
    modifiedBreadcrumb.push({ label: condominium.name, to: '' });
    return modifiedBreadcrumb;
  }, [condominium]);

  const patrolEnabled = useMemo(
    () =>
      condominium?.params?.some(
        param =>
          param?.name === 'patrolActivated' &&
          param?.value &&
          JSON.parse(param.value),
      ),
    [condominium],
  );

  const condoDetailDataProvider = useMemo(() => {
    return {
      query: { data, loading },
      condominium,
      breadcrumb,
      updateCondominium: refetch,
      patrolEnabled,
      condoWorkers,
      condoWorkersLoading,
    };
  }, [data, condoWorkersData]);

  return (
    <Context.Provider value={condoDetailDataProvider}>
      {children}
    </Context.Provider>
  );
};

export const useCondominiumDetails = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('useCondominiumDetails must be used within a Provider');
  }
  return context;
};

Provider.propTypes = {
  children: propTypes.any.isRequired,
  match: propTypes.object.isRequired,
  route: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
};

export default Provider;
